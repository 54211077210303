import "./App.css";

import { useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// routing
import AppRoute from "routes";
import NavigationScroll from "layout/NavigationScroll";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// defaultTheme
import themes from "themes";

// project imports

function App() {
  const customization = useSelector((state: any) => state.customization);
  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={themes(customization)}>
        <NavigationScroll>
          <CssBaseline />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            limit={1}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <AppRoute />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
