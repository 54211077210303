import Logo from "assets/images/frank-logo.png";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { defaultLabelColors } from "utils/constants";
import { convertToObject } from "typescript";

const navItems = [{ name: "Sign Up", url: '/signup' }, { name: "Log In", url: '/login' }];

const drawerWidth = 240;
export default function Header(props: any) {
  const navigate = useNavigate();

  const { classes } = props;
  interface Props {
    window?: () => Window;
  }
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <img
          src={Logo}
          style={{
            height: 50,
            width: "auto",
            objectFit: "contain",
          }}
        />
      </Typography>
      <Divider />
      {window.location.pathname === "/" ||
        window.location.pathname === "/home" ?
        navItems.map((item: any, index: any) => (
          <Button
            key={item.url}
            className={classNames(
              classes.button_width,
              classes.contained_button,

            )}
            style={{ backgroundColor: item.url === "/signup" ? `${defaultLabelColors.main_orange2}` : `${defaultLabelColors.main_orange}` }}
            onClick={() =>{
              navigate(item.url);
            }}
          >
            {item.name}
          </Button>
        )) : null}
    </Box>
  );

  // const container = window !== undefined ? () => window().document.body : undefined;
  if (window.location.pathname === "/login") {
    return null;
  }
  else if (window.location.pathname === "/signup") {
    return null;
  }
  else if(window.location.pathname==="/profile"){
    return null;
  }
  return (
    <div style={{ width: "100%" }}>
      <AppBar component="nav" className={classes.header_design}>
        <Toolbar>
          <IconButton
            // color={defaultLabelColors.main_blue}
            aria-label="open drawer"
            edge="start"

            onClick={handleDrawerToggle}
            sx={{
              color: "#ee6931",
              mr: 2, display: { sm: "none" },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <img
              src={Logo}
              style={{
                height: 50,
                width: "auto",
                objectFit: "contain",
              }}
            />
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {window.location.pathname === "/" ||
              window.location.pathname === "/home" ?
              navItems.map((item: any, index: any) => (
                <Button
                  key={item.url}
                  className={classNames(
                    classes.button_width,
                    classes.contained_button,
                  )}
                  style={{ backgroundColor: index === 1 ? `${defaultLabelColors.main_orange2}` : `${defaultLabelColors.main_orange}` }}
                  //signup page is not complete after signup page we link in
                  onClick={() => {
                    // navigate("/login");
                    navigate(item.url);

                  }}
                >
                  {item.name}
                </Button>
              )) : null}
              
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </div>
  );
}
