import { lazy } from "react";
import Loadable from "ui-components/Loadable";

export const NotFound = Loadable(lazy(() => import("views/NotFound")));
export const Home = Loadable(lazy(() => import("views/Home")));
export const Login = Loadable(lazy(() => import("views/Login/Login")));
export const Booking = Loadable(lazy(() => import("views/Booking")));
export const Checkout = Loadable(lazy(() => import("views/Checkout")));
export const Profile = Loadable(lazy(() => import("views/Profile")));
export const SignUp = Loadable(lazy(() => import("views/Signup")));
