// markerSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const markerSlice = createSlice({
  name: 'marker',
  initialState: {
    isBouncing: false,
  },
  reducers: {
    setMarkerBouncing: (state, action) => {
      state.isBouncing = action.payload;
    },
  },
});

export const { setMarkerBouncing } = markerSlice.actions;

export const selectMarkerBouncing = (state:any) => state.marker.isBouncing;

export default markerSlice.reducer;
