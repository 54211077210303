/*
  Created By Muhammad Hassaan
  Date 10 April 2023
*/
import { makeStyles } from "@mui/styles";
import { defaultLabelColors } from "utils/constants";
const useStyles = makeStyles((theme: any) => ({
  align_center: {
    display: "flex",
    alignItems: "center",
  },
  align_end: {
    display: "flex",
    alignItems: "flex-end",
  },
  justify_center: {
    display: "flex",
    justifyContent: "center",
  },
  justify_end: {
    display: "flex",
    justifyContent: "flex-end",
  },
  direction_row: {
    flexDirection: "row",
  },
  direction_column: {
    flexDirection: "column",
  },
  display_flex_baseline: {
    display: "flex",
    alignItems: "baseline",
  },

  breadcrumb_container: {
    padding: "10px 40px",
    background: theme.palette.frank.sand,
    boxShadow:
      "0px 2px 4px -1px #00000033, 0px 4px 5px 0px #00000024, 0px 1px 10px 0px #0000001f",
    // position: "fixed",
    // width: "100%",
    marginBottom: 10,
  },
  breadcrumb_head: {
    color: "#000000" || theme.palette.frank.black,
    fontFamily: "Roboto-Regular",
    fontSize: 34,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  breadcrumb_time: {
    fontFamily: "Roboto-Medium",
    color: theme.palette.frank.black,
    fontSize: 24,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  breadcrumb_description: {
    fontFamily: "Roboto-Regular",
    color: theme.palette.frank.black,
    fontSize: 18,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  contained_orange_button: {
    background: theme.palette.frank.appPrimary,
    fontFamily: "Roboto-Bold",
    border: "1px solid #ee6931",
    color: "white",
    transition: "1s",
    "&:hover": {
      background: theme.palette.frank.appPrimary,
      color: "white",
      transition: "1s",
    },
  },
  contained_blue_button: {
    background: "#3026f1",
    fontFamily: "Roboto-Bold",
    border: "1px solid #3026f1",
    color: "white",
    transition: "1s",
    "&:hover": {
      background: "#3026f1",
      color: "white",
      transition: "1s",
    },
  },
  main_layout_padding: {
    padding: "20px 20px 50px 20px",
  },

  colored_input_feild: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.frank.appSecondary,
    background: "#fff",
  },
  input_feild: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    background: "#fff",
    color: theme.palette.frank.black,
    borderRadius: "50px",
  },

  info_table: {
    fontSize: 18,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.primary.color_f,
  },

  background_sand: {
    background: theme.palette.frank.sand,
  },

  background_light_gray: {
    background: "#e0e0e0",
  },

  table_head: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.frank.black,
  },

  normal_text: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.frank.black,
  },
  simple_normal_text: {
    fontSize: 15,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.frank.black,
  },
  pagination_per_page: {
    padding: 5,
    borderRadius: 4,
    fontSize: 14,
    color: theme.palette.frank.appSecondary,
    background: theme.palette.frank.grey1,
    marginRight: 30,
  },
  color_secondary: {
    color: `${theme.palette.frank.appSecondary} !important`,
  },
  color_primary: {
    color: `${theme.palette.frank.appPrimary} !important`,
  },
  bg_gray: {
    backgroundColor: "#f6f6f6",
    background: "#f6f6f6",
  },
  primary_divider: {
    borderColor: theme.palette.frank.appPrimary,
    borderBottomWidth: "medium",
  },
  secondary_divider: {
    borderColor: theme.palette.frank.appSecondary,
    borderBottomWidth: "medium",
  },
  gray_divider: {
    borderColor: theme.palette.frank.grey1,
    borderBottomWidth: "medium",
    borderLeftWidth: "medium",
    borderRadius: 10,
  },
  pagination_container: {
    color: theme.palette.frank.appSecondary,
    "& ul": {
      "& li": {
        "& .Mui-selected": {
          background: theme.palette.frank.appSecondary,
          border: `1px solid ${theme.palette.frank.appSecondary}`,
          color: theme.palette.frank.sand,
          fontFamily: "Roboto-Regular",
          fontSize: 14,
          borderRadius: 3,
          "&:hover": {
            background: theme.palette.frank.appSecondary,
            color: theme.palette.frank.sand,
          },
        },
        "& button": {
          background: theme.palette.frank.grey1,
          color: theme.palette.frank.appSecondary,
        },
      },
    },
  },
  check_icon: {
    color: `${theme.palette.frank.appSecondary} !important`,
    height: 24,
    width: 24,
  },

  selected_row: {
    border: `2px solid ${theme.palette.frank.appSecondary}`,
  },
  unselected_row: {
    borderBottom: `none`,
    borderTop: `2px solid transparent`,
    borderLeft: `2px solid transparent`,
    borderRight: `2px solid transparent`,
  },
  input_label_style: {
    padding: "0px 0px 4px 10px",
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.frank.grey3,
  },
  color_red: {
    color: `red !important`,
  },
  //changing for new Space UI |width and height from 40(height) and 120(width) to ....//

  button_width: {
    // maxWidth: 150,
    minWidth: "153px",
    maxHeight: "54.14px",
    minHeight: "54.14px",
    textTransform: "none",
    boxShadow: "none",
    marginRight: 10,

    [theme.breakpoints.down("sm")]: {
      maxWidth: "none",
      minWidth: "0px",
      // marginBottom: 5,
    },
  },
  contained_button: {
    background: theme.palette.frank.appPrimary,
    color: defaultLabelColors.color_h,
    borderColor: theme.palette.frank.appPrimary,
    borderRadius: 10,
    transition: "1s",
    "&:hover": {
      background: theme.palette.frank.appPrimary,
      color: defaultLabelColors.color_h,
      borderColor: theme.palette.frank.appPrimary,
      transition: "1s",
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
  // changing outline color and border radius for new space UI
  outlined_button: {
    background: defaultLabelColors.color_h,
    color: "#000000",
    borderColor: defaultLabelColors.main_orange2,
    borderRadius: 10,
    transition: "1s",
    "&:hover": {
      background: defaultLabelColors.color_h,
      color: "#000000",
      border: `1px solid ${defaultLabelColors.main_orange}`,
      transition: "1s",
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  disabled_button: {
    borderRadius: 20,
    background: theme.palette.frank.grey1,
    color: theme.palette.frank.grey3,
    borderColor: theme.palette.frank.grey1,
    transition: "1s",
  },

  modal_background: {
    backgroundColor: theme.palette.frank.appSecondary,
    background: theme.palette.frank.appSecondary,
    padding: "10px 20px",
  },
  modal_title: {
    fontSize: 22,
    fontWeight: "normal",
    lineHeight: "normal",
    color: defaultLabelColors.color_h,
    letterSpacing: "0.015em",
  },
  card_label_style: {
    fontSize: 16,
    color: "gray",
    fontFamily: "Roboto-Medium",
  },
  card_value_style: {
    fontSize: 16,
    color: theme.palette.frank.black,
    fontFamily: "Roboto-Medium",
  },
  profile_name: {
    fontSize: 20,
    color: theme.palette.frank.black,
    fontFamily: "Roboto-Bold",
    letterSpacing: 1.5,
  },
  // change in active tab due to new Sapce ui
  active_tab: {
    borderRadius: "5px 5px 0px 0px",
    color: `${defaultLabelColors.main_orange}`,
    letterSpacing: ": 0.05em",
    fontSize: 18,
    fontFamily: "RobotoCondensed-Medium",
    borderBottom:`2px solid ${defaultLabelColors.main_orange}`
  },
  inactive_tab: {
    color: theme.palette.frank.grey3,
    letterSpacing: ": 0.05em",
    fontSize: 18,
    fontFamily: "RobotoCondensed-Medium",
  },
  accordion_head_container: {
    backgroundColor: `${theme.palette.frank.sand} !important`,
    background: `${theme.palette.frank.sand} !important`,
    width: "100%",
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: "3px 3px 0px 0px ",
    minHeight: `38px !important`,
    height: `38px !important`,
  },
  accordion_heading: {
    fontSize: 20,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "0.015em",
    color: theme.palette.frank.black,
    fontFamily: "Roboto-Medium",
  },
  accordion_icons: {
    fontSize: 20,
    color: theme.palette.frank.grey3,
  },
  accordion_container: {
    border: `1px solid ${theme.palette.frank.sand}`,
  },
  accordion_detail_padding: {
    padding: "24px 32px 30px",
  },
  toggle_sections: {
    color: theme.palette.frank.appSecondary,
    fontSize: 16,
    paddingLeft: 15,
    fontFamily: "Roboto-Regular",
    cursor: "pointer",
  },
  view_image_text: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    fontFamily: "RobotoCondensed-Regular",
    color: theme.palette.frank.appSecondary,
  },
  dialog_footer_info: {
    fontSize: 14,
    fontFamily: "RobotoCondensed-Regular",
    color: theme.palette.frank.grey3,
    width: "max-content",
  },
  dialog_description: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.frank.grey3,
    fontFamily: "Roboto-Medium",
  },
  dialog_description_2: {
    fontSize: 16,
    color: theme.palette.frank.appSecondary,
    fontFamily: "RobotoCondensed-Regular",
  },
  radio_label: {
    fontSize: 16,
    fontFamily: "Roboto-Medium",
    color: theme.palette.frank.appSecondary,
  },

  unselect_radio_label: {
    fontSize: 16,
    fontFamily: "Roboto-Medium",
    color: theme.palette.frank.grey3,
  },
  setting_icons: {
    color: theme.palette.frank.appSecondary,
    height: 60,
    width: "100%",
  },
  setting_container: {
    background: "#ffffff",
    margin: 30,
    padding: 20,
    border: `2px solid ${theme.palette.frank.appLightPrimary}`,
    borderRadius: 5,

    [theme.breakpoints.down("sm")]: {
      // margin: 50,
    },
  },
  setting_heading: {
    fontSize: 18,
    fontFamily: "Roboto-Medium",
  },
  setting_description: {
    fontSize: 15,
    fontFamily: "Roboto-Regular",
    color: theme.palette.frank.grey3,
  },
  setting_hover: {
    transition: "0.5s",
    padding: 10,
    borderRadius: 10,
    "&:hover": {
      background: theme.palette.frank.sand,
      transition: "0.5s",
    },
  },
  switch_label: {
    fontSize: 16,
    fontFamily: "Roboto-Regular",
    color: theme.palette.frank.appSecondary,
  },
  rate_dialog_label: {
    fontSize: 16,
    fontFamily: "Roboto-Medium",
    color: theme.palette.frank.grey3,
  },
  responsive_width: {
    width: "30%",
    [theme.breakpoints.down("xl")]: {
      width: "50%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  commission_label: {
    fontSize: 16,
    color: theme.palette.frank.grey3,
    fontFamily: "RobotoCondensed-Medium",
  },
  dashboard_card_head: {
    fontSize: 30,
    color: theme.palette.frank.appSecondary,
    fontFamily: "RobotoCondensed-Medium",
  },
  dashboard_card_title: {
    fontSize: 16,
    color: theme.palette.frank.appSecondary,
    fontFamily: "Roboto-Medium",
  },
  dashboard_card_container: {
    padding: 10,
    border: `1px solid ${theme.palette.frank.appSecondary}`,
    borderRadius: 5,
    position: "relative",
    boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)`,
  },
  background_image_position: {
    backgroundPosition: "top",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
  },
  background_primary: {
    background: theme.palette.frank.appPrimary,
  },
  login_card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    width: "65%",
    [theme.breakpoints.down("xs")]: {
      width: "85%",
    },
  },
  opening: {
    color: theme.palette.frank.appPrimary,
    fontFamily: "RobotoCondensed-Medium",
    width: "100%",
    textAlign: "center",
    fontSize: 20,
    "& h1": {
      display: "block",
      margin: 0,
      lineHeight: 1.3,
      color: "#fafafa",
      [theme.breakpoints.down("md")]: {
        fontSize: 32,
        lineHeight: "48px",
      },
    },
    "& p": {
      color: theme.palette.frank.appPrimary,
      fontSize: 18,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
    },
  },
  signin_image_container: {
    width: "70%",
    background: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      display: "none",
      // width: "100%",
      // width: "70%",
      background: "#ffffff",
      // display: "flex",
      // alignItems: "center",
      // justifyContent: "center",
      // height: "100vh",
    },
  },
  signin_head: {
    fontFamily: "RobotoCondensed-Medium",
    fontSize: 34,
    // letterSpacing: 1.2,
    color: theme.palette.frank.appPrimary,
    textTransform: "uppercase",
  },
  text_1_with_background: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    background: theme.palette.frank.appLightPrimary,
    padding: 10,
    paddingLeft: 20,
    color: theme.palette.frank.appSecondary,
    textTransform: "capitalize",
  },

  text_2_active_background: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.frank.appLightPrimary,
    background: theme.palette.frank.appSecondary,
  },
  // NEW CLASSES FOR SPACE NEW UI
  header_design: {
    background: theme.palette.background.paper,
    height: "10vh",
    border: `2px solid ${theme.palette.primary.light}`,
    boxShadow: "none",
  },
  text1_style: {
    color: theme.palette.frank.appPrimary,
    fontSize: 20,
    fontFamily: "InterTight-Regular",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  main_text_style: {
    color: theme.palette.frank.appPrimary,
    fontSize: 60,
    fontFamily: "InterTight-Medium",
    lineHeight: "1.2em",
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
    },
  },
  text_font: {
    [theme.breakpoints.down("xl")]: {
      fontSize: 15,
    },
  },
  center_item: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text_para: {
    color: theme.palette.text.black,
    lineHeight: "1.3em",
    paddingBottom: "15px",
    fontFamily: "InterTight-Light",
  },
  btn_color: { color: theme.palette.frank.secondary },
  btn_play_icon: {
    color: theme.palette.frank.appPrimary,
    width: 50,
    height: 50,
    borderRadius: "50%",
    boxShadow: "0px 1px 3px 1px #eee6931",
  },
  btn_play: {
    color: theme.palette.frank.appPrimary,
    width: 40,
    height: 40,
    borderRadius: "50%",
    boxShadow: "5px 5px 5px 5px solid red",
  },
  home_image: {
    height: "90vh",
    objectFit: "cover",
    // position: "absolute",
    // right: "0px",
    [theme.breakpoints.down("sm ")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("lg")]: {
      // display:"none",
    },
  },
  heading_font: {
    fontFamily: "InterTight-Regular",
  },
  log_image: {
    height: "100vh",
    objectFit: "cover",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      // display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("lg")]: {
      // display:"none",
    },
  },
  align_logIn: {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",
  },

  signup_image: {
    height: "85vh",
    objectFit: "contain",
    width: "100%",
    // [theme.breakpoints.down("xs")]: {
    //   display: "none",
    //   backgroundColor: "blue", // Assigning blue for extra small screens
    // },
    [theme.breakpoints.down("sm")]: {
      display: "none",
      // backgroundColor: "green", // Assigning green for small screens
    },
    [theme.breakpoints.down("md")]: {
      // display: "none",
      // backgroundColor: "red", // Assigning red for medium screens
    },
    [theme.breakpoints.down("lg")]: {
      // backgroundColor: `yellow`, // Assigning yellow for large screens
      // height: "90vh",
    },
    [theme.breakpoints.down("xl")]: {
      // backgroundColor: "pink", // Assigning pink for extra large screens
    },
  },

  align_signup: {
    display: "flex",
  },
  marginTop_signup: {
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0vh",
    },
  },
  marginTop_booking: {
    marginTop: "15vh",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5vh",
    },

  },
  booking_header_height:{
    [theme.breakpoints.down("sm")]:{
      height:"max-content",
    },
    // [theme.breakpoints.down("md")]:{
    //   height:"15vh",
    //   backgroundColor:"yellow",

    // },
    // [theme.breakpoints.down("lg")]:{
    //   height:"10vh",
    //   backgroundColor:"pink",

    // },
  },
  profile_box:{

    backgroundColor:"",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",
    [theme.breakpoints.down("sm")]:{
      display:"none",
    },
    
  },
  //booking box//
  font_class:{
    fontFamily:"InterTight-Light",
    color:"Black"
  },
  pickdrop_box:{
    padding: "10px",
    borderRadius: "4px",
    width: "100%",
    backgroundColor:defaultLabelColors.main_orange,
  },
  hover_underline:{
 "&:hover": {
      
      transition: "1s",
      textDecoration:"underline",
    },
  },
  newOutlined_button: {
    background: defaultLabelColors.color_h,
    color: "#000000",
    border: `1px solid ${defaultLabelColors.main_blue}`,

    borderRadius: 10,
    transition: "1s",
    "&:hover": {
      background: defaultLabelColors.color_h,
      color: "#000000",
      border: `1px solid ${defaultLabelColors.main_blue}`,
      transition: "1s",
      // boxShadow:
      //   "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
}));

export default useStyles;
