import { useRoutes } from "react-router-dom";
import { MainAppRoutes } from "./MainAppRoutes";
import useStyles from "./style-jss";
import { saveDataToLocalStorage } from "utils/LocalStore.util";

// routes
// import MainRoutes from "./MainAppRoutes";

export function AppRoute() {
  const classes = useStyles() as any;

  let app_routes = MainAppRoutes({ classes });

  // eslint-disable-next-line
  return useRoutes([app_routes]);
}

export default AppRoute;
