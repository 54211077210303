import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router";
import { RootState } from "store";
import { drawerWidth } from "store/constants";
import Header from "./Header";

// styles
const Main = styled("main", {
  shouldForwardProp: (prop: any) => prop !== "open",
})(({ theme, open }: any) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: -drawerWidth,
      // width: `calc(100% - ${drawerWidth}px)`,
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      // width: `calc(100% - ${drawerWidth}px)`,
      width: "100%",
      padding: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: "100%",
      // width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
      marginRight: "10px",
    },
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: "100%",
    // width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: "100%",
    },
  }),
  // marginTop: 48,
}));

export default function MainLayout(props: any) {
  const theme = useTheme() as any;

  const leftDrawerOpened = useSelector(
    (state: RootState) => state.drawer.opened
  );

  const dispatch = useDispatch();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.frank.appSecondary,
          transition: leftDrawerOpened
            ? theme.transitions.create("width")
            : "none",
          display: (
            window.location.pathname === "/signup" || 
            window.location.pathname === "/login"  ||
            window.location.pathname==="/booking"  ||
            window.location.pathname==="/profile"

          ) ? "none" : "block",
        }}
      >
        <Toolbar style={{ padding: 0 }}>
          <Header
            {...props}
          // handleLeftDrawerToggle={handleLeftDrawerToggle}
          // drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
          />
        </Toolbar>
      </AppBar>


      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened}>
        <Outlet />
      </Main>
    </Box>
  );
}
