export const defaultLabelColors = {
  color_e: "#181818",
  color_f: "#666666",
  color_g: "#eeeeee",
  color_h: "#f8f8f8",

  main_orange: "#ee6931",
  main_orange2:"#fa4514",
  light_orange: "#f9c9b3",
  sand: "#f9d9b6",
  main_blue: "#3026f1",
  dark_blue: "#191847",
  orange_yellow: "#d3be00",
  green: "green",
  red: "#f10000",
  lightgray: "lightgray",
  gray: "#c5c5c5",
  gray2: "gray",
};

export const dateRangeFilters = [
  { label: "All Time Records", value: "all_time" },
  { label: "This Year", value: "this_year" },
  { label: "This Quarter", value: "this_quarter" },
  { label: "This Month", value: "this_month" },
  { label: "This Week", value: "this_week" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Previous Week", value: "previous_week" },
  { label: "Previous Month", value: "previous_month" },
];
export const darkModeWithBlue = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#121212", // Dark background color
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#BBDEFB", // Blue text color
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#121212", // Dark text stroke color
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#64B5F6", // Blue land parcel text color
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#0D47A1", // Dark blue POI color
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#BBDEFB", // Blue POI text color
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#0D47A1", // Dark blue park color
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#BBDEFB", // Blue park text color
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#263238", // Dark road color
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#BBDEFB", // Blue road text color
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#263238", // Dark blue highway color
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: defaultLabelColors.color_h, // Blue highway text color
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#BBDEFB", // Blue local road text color
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#0D47A1", // Dark blue transit line color
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#0D47A1", // Dark blue transit station color
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#003A7D", // Dark blue water color
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#BBDEFB", // Blue water text color
      },
    ],
  },
];