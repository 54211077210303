import MainLayout from "layout/MainLayout";
import { Booking, Home, Login, NotFound, Profile, SignUp } from "./PageAsync";
import PropTypes from "prop-types";
import Checkout from "views/Checkout";
// ==============================|| MAIN ROUTING ||============================== //

// export default MainRoutes;

export function MainAppRoutes({ classes }: any) {
  const MainRoutes = {
    path: "/",
    element: <MainLayout classes={classes} />,
    children: [
      {
        path: "/",
        element: <Home classes={classes} />,
      },
      {
        path: "login",
        element: <Login classes={classes} />,
      },
      {
        path: "booking",
        element: <Booking classes={classes} />,
      },
      // {
      //   path: "/Checkout",
      //   element: <Checkout classes={classes} />,
      // },
      {
        path: "profile",
        element: <Profile classes={classes} />,
      },
      {
        path:"signup",
        element:<SignUp classes={classes}/>,
      },
      {
        path: "/*",
        element: <NotFound classes={classes} />,
      },
    ],
  };
  return MainRoutes;
}

MainAppRoutes.propTypes = {
  classes: PropTypes.object,
};
